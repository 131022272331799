import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'login',
    component: ()=> import('../views/login.vue')
  },
  {
    path: '/main',
    name: 'main',
    component: () => import('../views/mainview/index.vue')
  }
]

const originalPush = VueRouter.prototype.push;
const originalReplace = VueRouter.prototype.replace;
 
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

VueRouter.prototype.replace = function replace(location) {
  return originalReplace.call(this, location).catch(err => err)
}

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
});

// 路由守卫
router.beforeEach((to, from, next) => {
  if(to.name == from.name) {
    return;
  }
  next()
});


export default router
