export default {
    m: {
        text1: "賬號登陸",
        text2: "注冊賬號",
        text3: "賬號密碼登錄",
        text4: "手機驗登錄",
        text5: "區號",
        text6: "請輸入登錄賬號",
        text7: "請輸入登錄密碼",
        text8: "請輸入手機驗證碼",
        text9: "登錄",
        text10: "注冊賬號",
        text11: "選擇區號",
        text12: "請選擇區號",
        text13: "請輸入登錄手機號",
        text14: "請輸入登錄密碼",
        text15: "請輸入手機驗證碼",
        text16: "請輸入Google驗證碼",
        text17: "加載中...",
        text18: "賬號：",
        text19: "保證金：",
        text20: "繳納",
        text21: "參考買價：",
        text22: "參考賣價：",
        text23: "安全設置：",
        text24: "密碼/驗證碼管理",
        text25: "賬戶設置：",
        text26: "實名認證",
        text27: "支付管理",
        text28: "退出登錄",
        text29: "我的資產",
        text30: "存幣",
        text31: "提幣",
        text32: "資產記錄",
        text33: "繳納保證金",
        text34: "未實名",
        text35: "實名成功",
        text36: "實名中",
        text37: "實名失敗",
        text38: "存幣消息",
        text39: "提幣消息",
        text40: "實名審核",
        text41: "快捷賣單",
        text42: "快捷買單",
        text43: "暫不支持該幣種",
        text44: "提幣記錄",
        text45: "存幣記錄",
        text46: "流水記錄",
        text47: "修改登錄密碼",
        text48: "修改支付密碼",
        text49: "修改Google驗證器",
        text50: "我的餘額：",
        text51: "保證金：",
        text52: "確定繳納",
        text53: "餘額不足",
        text54: "繳納成功",
        text55: "轉出類型：",
        text56: "手機號",
        text57: "收幣地址：",
        text58: "請輸入收幣地址",
        text59: "收幣手機號：",
        text60: "請輸入收幣手機號",
        text61: "轉出數量：",
        text62: "請輸入轉出數量",
        text63: "交易密碼：",
        text64: "請輸入交易密碼",
        text65: "手續費：",
        text66: "轉出手續費",
        text67: "轉出手續費",
        text68: "手續費將依據市場價格幅度而隨時調整",
        text69: "提交申請",
        text70: "溫馨提示：",
        text71: "1.最小提幣數量為(100USDT、0.05ETH、0.01BTC)。",
        text72: "2.BEP20 預計到賬時間為2-10分鐘，TRC20為0.1-5分鐘，ETH或BTC所需時間可能更長。手機號轉帳為內部轉帳，免手續費且即刻到帳。",
        text73: "3.基於不同協議之間的USDT不能相互轉帳，請您確認類型後再進行轉帳。註: 基於 BEP20 協議發行的地址為0x開頭，而基於TRC20協議發行的地址為 [T]開頭。",
        text74: "4.為確保您的資產安全，建議您於工作日(09:00 - 18:00)，周末(09:00  13:00)申請提幣，其他時間提幣將有所延緩。",
        text75: "5.請務必確認網絡環境安全。",
        text76: "選擇區號",
        text77: "請輸入轉出地址",
        text78: "請選擇區號",
        text79: "請輸入轉出手機號",
        text80: "請輸入轉出數量",
        text81: "請輸入交易密碼",
        text82: "提交成功",
        text83: "快捷買單",
        text84: "開啓",
        text85: "關閉",
        text86: "今日成交：",
        text87: "搜索訂單",
        text88: "展開",
        text89: "收起",
        text90: "日期：",
        text91: "開始",
        text92: "結束",
        text93: "訂單號：",
        text94: "請輸入訂單號",
        text95: "訂單狀態：",
        text96: "請選擇",
        text97: "異常狀態：",
        text98: "退款狀態：",
        text99: "搜索",
        text100: "暫無訂單",
        text101: "訂單號：",
        text102: "訂單信息：",
        text103: "用戶",
        text104: "交易數量",
        text105: "單價",
        text106: "支付金額",
        text107: "訂單操作：",
        text108: "確認交易",
        text109: "查看付款信息",
        text110: "複製訂單號",
        text111: "支付信息",
        text112: "待付款",
        text113: "待確認",
        text114: "客戶取消",
        text115: "交易完成",
        text116: "交易員取消",
        text117: "超時取消",
        text118: "系統取消",
        text119: "異常審核中",
        text120: "異常審核駁回",
        text121: "異常審核通過",
        text122: "錄入退款信息",
        text123: "退款中",
        text124: "已退款",
        text125: "已收款",
        text126: "未收到款",
        text127: "退款失敗",
        text128: "平臺取消",
        text129: "操作成功",
        text130: "複製成功",
        text131: "複製失敗",
        text132: "快捷賣單",
        text133: "賣單每日限額：",
        text134: "今日成交：",
        text135: "收款金額",
        text136: "支付",
        text137: "取消",
        text138: "确认支付",
        text139: "取消成功",
        text140: "複製成功",
        text141: "複製失敗",
        text142: "銀行卡",
        text143: "支付寶",
        text144: "卡號：",
        text145: "持卡人：",
        text146: "支行：",
        text147: "已用/當日限額：",
        text148: "接單數/上限：",
        text149: "是否接單：",
        text150: "賬號",
        text151: "持有人：",
        text152: "交易金額：",
        text153: "是否接單：",
        text154: "新增銀行卡",
        text155: "新增支付寶賬戶",
        text156: "新增HIBIT賬號",
        text157: "刪除賬戶",
        text158: "請聯係上級開通支付寶綁定權限",
        text159: "您還未實名認證，請提交相關信息",
        text160: "真實姓名：",
        text161: "護照號碼：",
        text162: "請輸入真實姓名",
        text163: "請輸入護照號碼",
        text164: "護照信息相片",
        text165: "手持護照相片",
        text166: "提交申請",
        text167: "等待系統審核",
        text168: "實名成功",
        text169: "實名被拒絕",
        text170: "重新申請",
        text171: "請上傳圖片",
        text172: "請輸入真實姓名",
        text173: "請輸入護照號碼",
        text174: "請上傳護照照片",
        text175: "請上傳手持護照照片",
        text176: "提交成功，等待審核",
        text177: "存入類型：",
        text178: "存幣地址：",
        text179: "存幣地址：",
        text180: "複製地址",
        text181: "溫馨提示：",
        text182: "1.最小存幣數量為USDT-BEP2020:20;USDT-TRC20:1; BTC:0.003; ETH:0.05;",
        text183: "小於最小數量將無法到賬亦無法退款",
        text184: "2.BEP20預計到帳時間為2-10分鐘，TRC20為0.1-5分鐘，如遇鏈上擁堵則會增加時長",
        text185: "3.BEP20、TRC20地址不同且不互通；請仔細確認地址類型，避免因錯轉而無法到帳。註: 基於BEP20 協議發行的地址為[0x]開頭;基於TRC20 協議發行的地址為 [T] 開頭。",
        text186: "5.復製並粘貼地址後，認真核對與您復製的地址前、中、後完全相符再進行存幣，如不相符平臺無法協助尋回，請再次確認，以避免重要資產發生損失。",
        text187: "如有疑問，",
        text188: "銀行名稱：",
        text189: "請輸入銀行名稱",
        text190: "賬戶號碼：",
        text191: "請輸入賬戶號碼",
        text192: "持卡人：",
        text193: "請輸入持卡人姓名",
        text194: "開戶分行：",
        text195: "請輸入開戶分行",
        text196: "銀行地址：",
        text197: "請輸入銀行地址",
        text198: "銀行編號：",
        text199: "請輸入銀行編號",
        text200: "國際匯款編碼：",
        text201: "請輸入國際匯款編碼",
        text202: "每日最大收款額度：",
        text203: "請輸入每日最大額度",
        text204: "接單上限：",
        text205: "請輸入接單上限",
        text206: "是否開啓：",
        text207: "支付密碼：",
        text208: "請輸入支付密碼",
        text209: "谷歌驗證碼：",
        text210: "請輸入谷歌驗證碼",
        text211: "確定",
        text212: "請輸入銀行名稱",
        text213: "請輸入賬戶號碼",
        text214: "請輸入持卡人姓名",
        text215: "請輸入開戶分行",
        text216: "請輸入銀行地址",
        text217: "請輸入銀行編號",
        text218: "請輸入國際匯款編碼",
        text219: "請輸入每日最大額度",
        text220: "請輸入接單上限",
        text221: "請輸入支付密碼",
        text222: "請輸入谷歌驗證碼",
        text223: "賬號：",
        text224: "請輸入HIBIT賬號",
        text225: "持有人姓名：",
        text226: "請輸入持有人姓名",
        text227: "收款二維碼：",
        text228: "是否開啓：",
        text229: "支付密碼：",
        text230: "請輸入支付密碼",
        text231: "谷歌驗證碼：",
        text232: "請輸入谷歌驗證碼",
        text233: "請上傳收款二維碼",
        text234: "添加成功",
        text235: "賬號：",
        text236: "請輸入支付寶賬號",
        text237: "搜索區號",
        text238: "請選擇區號！",
        text239: "收款類型：",
        text240: "收款賬號：",
        text241: "收款人姓名：",
        text242: "銀行名稱：",
        text243: '收款碼：',
        text244: "請上傳支付憑證：",
        text245: "確認支付",
        text246: "請上傳支付憑證",
        text247: "支付成功",
        text248: "您正在刪除賬戶，請完成以下驗證",
        text249: "確定刪除",
        text250: "刪除成功",
        text251: "手機轉出",
        text252: "收款地址",
        text253: "金額",
        text254: "手續費",
        text255: "狀態",
        text256: "時間",
        text257: "待審核",
        text258: "成功",
        text259: "失敗",
        text260: "訂單號：",
        text261: "請輸入訂單號",
        text262: "交易類型：",
        text263: "時間：",
        text264: "數量",
        text265: "總額",
        text266: "時間",
        text267: "賣出",
        text268: "賣出取消",
        text269: "買入",
        text270: "存幣",
        text271: "提幣扣除",
        text272: "提幣取消",
        text273: "提幣礦工費",
        text274: "退回手續費",
        text275: "保證金",
        text276: "保證金贖回",
        text277: "獲取",
        text278: "請輸入手機號",
        text279: "1輸入手機號和密碼",
        text280: "2綁定Google",
        text281: "3完成注冊",
        text282: "手機號：",
        text283: "登錄密碼：",
        text284: "請輸入登錄密碼",
        text285: "支付密碼：(格式爲6位純數字)",
        text286: "請輸入支付密碼",
        text287: "驗證碼：",
        text288: "請輸入短信驗證碼",
        text289: "下一步",
        text290: "掃描下方二維碼綁定Google驗證器",
        text291: "Google驗證碼：",
        text292: "請輸入驗證碼",
        text293: "上一步",
        text294: "恭喜你注冊完成",
        text295: "完成注册",
        text296: "支付密碼格式為6位純數字",
        text297: "獲取Google驗證碼信息失敗",
        text298: "請輸入Google驗證碼",
        text299: "注冊失敗",
        text300: "請選擇區號",
        text301: "該用戶已存在",
        text302: "獲取新的驗證器",
        text303: "綁定",
        text304: "獲取成功",
        text305: "請輸入新的驗證信息",
        text306: "綁定成功",
        text307: "新的登錄密碼：",
        text308: "請輸入新的登錄密碼",
        text309: "確認密碼：",
        text310: "請確認新的登錄密碼",
        text311: "確認修改",
        text312: "兩次密碼輸入的不相同",
        text313: "修改成功",
        text314: "新的支付密碼：(注意：要求為6位純數字)",
        text315: "請輸入新的支付密碼",
        text316: "確認支付密碼：",
        text317: "請確認支付密碼",
        text318: "確認設置",
        text319: "來源",
        text320: "金額",
        text321: "狀態",
        text322: "時間",
        text323: "處理中",
        text324: "成功",
        text325: "失敗",
        text326: "語言設置：",
        text327: "修改限額",
        text328: "請設置交易限額：",
        text329: "請輸入限額",
        text330: "確定修改",
        text331: "綫上錢包/數字錢包",
        text332: "請輸入賬號",
        text333: "賬號類型：",
        text334: "郵箱",
        text335: "請輸入郵箱號",
        text336: "郵箱：",
        text337: "請輸入正確的郵箱地址",
        text338: "天",
        text339: "小時",
        text340: "分",
        text341: "秒",
        text342: "異常處理",
        text343: "異常類型：",
        text344: "非本人實名付款",
        text345: "付款金額不一致",
        text346: "未收到付款",
        text347: "退款數量：",
        text348: "請輸入退款數量",
        text349: "請選擇異常類型",
        text350: "清空",
        text351: "真實姓名格式不正確，請確保全爲大寫字母",
        text352: "提示音",
        text353: "付款賬戶",
        text354: "支付方式",
        text355: "支付賬號",
        text356: "付款人",
        text357: "收款賬戶",
        text358: "收款方式",
        text359: "收款賬號",
        text360: '收款人',
        text361: "拒絕原因：",
        text362: "補款",
        text363: "補款上傳憑證",
    }
}