const requestUrl = "https://api.gtotc.top/"
// const requestUrl = "http://172.16.148.47:8700/";
import ElementUI from 'element-ui';
import { Loading } from 'element-ui';
import router from '@/router/index';
export default {
    install(Vue) {
		Vue.use(ElementUI);
		// 网络请求
		// 描述: 网络请求
		// 参数: 
		(Vue.prototype.$network = (url,methods="post",isToken,paramse,call,showLoading=true,fail)=>{
			var headers = {};
			let loadingInstance = null;
			if(showLoading) {
				loadingInstance = Loading.service({fullscreen: true,background: 'rgba(0,0,0,0)', text: "Loding..."});
			}
            var token = localStorage.getItem("token");
			var lanText =  localStorage.getItem("lan");
            if(isToken) {
				if(!token){
					router.push({path:'/'})
				}
                headers = {
                    'Content-Type' : 'application/json',
					"language": lanText,
                    'token': token,
                }
            }else{
                headers = {
                    'Content-Type' : 'application/json',
					"language": lanText
                }
            }
			if(methods == "get") {
				let paramsArr = [];
				let urlParams = "";
				if(JSON.stringify(paramse) != {}) {
					for(let key in paramse) {
						paramsArr.push(key + '=' + paramse[key]);
					}
					urlParams = paramsArr.join("&");
					url = url + "?" + urlParams;
				}
			}
            fetch(requestUrl + url,{
                method: methods,
                body: methods == 'get' ? null : JSON.stringify(paramse),
                headers: headers
            }).then(function(data) {
                return data.json();
            }).then(function (data) {
				if(showLoading) {
					loadingInstance.close();
				}
                if(data.code == 200) {
                    call(data);
                }else if(data.code == 401){
					if(showLoading) {
						loadingInstance.close();
					}
					localStorage.removeItem("token");
                    // ElementUI.Message.error("Login has expired, please log in again");
                    router.replace('/');
                }else{
					if(showLoading) {
						loadingInstance.close();
					}
					if(fail) {
						fail();
					}
                    ElementUI.Message.error(data.message);
                }
            }).catch(function(res) {
                if(showLoading) {
					loadingInstance.close();
				}
				if(fail) {
					fail();
				}
				ElementUI.Message.error(res);
            });
		});


		// 文件上传
		(Vue.prototype.$upLoadFile = (file,call,showLoading=true)=>{
			const formData = new FormData(); // 创建FormData对象
			formData.append('file',file); // 将文件添加到FormData中
			var headers = {};
			let loadingInstance = null;
			if(showLoading) {
				loadingInstance = Loading.service({fullscreen: true});
			}
            var token = localStorage.getItem("token");
			headers = {
				'token': token,
			}
            fetch(requestUrl + 'file/upload/image',{
                method: 'POST',
                body: formData,
                headers: headers
            }).then(function(data) {
                return data.json();
            }).then(function (data) {
				if(showLoading) {
					loadingInstance.close();
				}
				if(data.code == 200) {
					call(data.result);
				}else{
					ElementUI.Message.error("Upload failed");
				}
            }).catch(function(res) {
				console.log(res);
                if(showLoading) {
					loadingInstance.close();
				}
				ElementUI.Message.error("Upload failed");
            });
		});


		// 获取当前时间或指定时间
		// 描述返回当前时间 格式 yyyy-MM-DD hh:mm:ss
		// 参数： time@String
		// 返回值： @String 格式化后的时间
		(Vue.prototype.getDayDate = (time,isDate=false)=>{
			var dateObj = null; 
			if(time){
				dateObj = new Date(time);
			} else {
				dateObj = new Date();
			}
			var year = dateObj.getFullYear();
			var month = dateObj.getMonth()+1;
			var day = dateObj.getDate();
			var hours = dateObj.getHours();
			var minute = dateObj.getMinutes();
			var seconds = dateObj.getSeconds();
			month = month>10?month:'0'+month;
			day = day>10?day:'0'+day;
			hours = hours>10?hours:'0'+hours;
			minute = minute>10?minute:'0'+minute;
			seconds = seconds>10?seconds:'0'+seconds;
			if(isDate) {
				return year + '-' + month + '-' + day;
			}else{
				return year + '-' + month + '-' + day + ' ' + hours + ':' + minute + ':' + seconds;
			}
		});
	   
		// 电话号码截取
		// 描述: 对手机号进行截取加密
		// 参数: phone@String：需要加密的手机号
		// 返回值：@String 加密后的手机号文字
		(Vue.prototype.substrPhone = (phone)=>{
			if(!phone){
				return;
			}
			var head = phone.substr(0,7);
			var leng = phone.substr(phone.length-4,4);
			return head + "**" + leng;
		});
	   
		// 文字截取
		// 描述将指定的文字截取到指定的数量
		//参数： str@String  len@Number 
		//返回值： @String 截取后的字符
		(Vue.prototype.substrText = (str,len)=>{
			if(str){
				if(str.length > len) {
				   return str.substr(0,len) + '...';
				}else{
				   return str;
				}
			}
		});
	   
		// 将数值转化为格式化的金额
		(Vue.prototype.formatNum = (num)=>{
		   if(!num) return 0.00;
			var str = num.toString();
			var strlen = null;
			if(str.indexOf('.') != -1) {
				var strArr = str.split('.');
				str = strArr[0];
				strlen = strArr[1];
			}
			var result = '';
			while(str.length > 3) {
				result = ',' + str.slice(str.length - 3, str.length) + result;
				str = str.slice(0,str.length - 3);
			}
			if(str.length <= 3 && str.length > 0) {
				result = str + result;
			}
			strlen ? result = result + '.' + strlen : ''
			return result;
		});
	   
		// 处理小数点
		(Vue.prototype.decimalAction = (num,len = 2)=>{
			var strNum = num + "";
			if(strNum.indexOf(".") != -1) {
				var array = strNum.split(".");
				if(array[1].length > 2){
				   var head = array[0];
				   var len = array[1].substr(0,len);
				   return head + "." + len;
				}else{
					return strNum;
				}
			}else{
			   return strNum;
			}
		});

		// 科学计算法
		(Vue.prototype.toFixedNum = (x) => {
			if (Math.abs(x) < 1.0) {
                var e = parseInt(x.toString().split('e-')[1]);
                if (e) {
                    x *= Math.pow(10,e-1);
                    x = '0.' + (new Array(e)).join('0') + x.toString().substring(2);
                }
              } else {
                var e = parseInt(x.toString().split('+')[1]);
                if (e > 20) {
                    e -= 20;
                    x /= Math.pow(10,e);
                    x += (new Array(e+1)).join('0');
                }
              }
              return x;
		});
    },
};
