export default {
    m: {
        text1: "Account Login",
        text2: "Register Account",
        text3: "Account Password Login",
        text4: "Phone Login",
        text5: "Area Code",
        text6: "Please enter login account",
        text7: "Please enter login password",
        text8: "Please enter phone verification code",
        text9: "Login",
        text10: "Register Account",
        text11: "Select Area Code",
        text12: "Please select area code",
        text13: "Please enter login phone number",
        text14: "Please enter login password",
        text15: "Please enter phone verification code",
        text16: "Please enter Google verification code",
        text17: "Loading...",
        text18: "Account:",
        text19: "Margin:",
        text20: "Pay",
        text21: "Reference Buy Price:",
        text22: "Reference Sell Price:",
        text23: "Security:",
        text24: "Password/Verification",
        text25: "Account:",
        text26: "Real Name",
        text27: "Payment",
        text28: "Logout",
        text29: "My Assets",
        text30: "Deposit",
        text31: "Withdraw",
        text32: "Records",
        text33: "Pay Margin",
        text34: "Not Real",
        text35: "Real Finish",
        text36: "Process",
        text37: "Real Failure",
        text38: "Deposit Messages",
        text39: "Withdraw Messages",
        text40: "Real-name Review",
        text41: "Quick Sell Orders",
        text42: "Quick Buy Orders",
        text43: "This currency is not supported temporarily",
        text44: "Withdrawal Records",
        text45: "Deposit Records",
        text46: "Transaction Records",
        text47: "Modify Login Password",
        text48: "Modify Payment Password",
        text49: "Modify Google Authenticator",
        text50: "My Balance:",
        text51: "Margin:",
        text52: "Confirm Payment",
        text53: "Insufficient Balance",
        text54: "Payment Success",
        text55: "Transfer Type:",
        text56: "Phone Number",
        text57: "Receive Address:",
        text58: "Please enter receive address",
        text59: "Receive Phone Number:",
        text60: "Please enter receive phone number",
        text61: "Transfer Amount:",
        text62: "Please enter transfer amount",
        text63: "Transaction Password:",
        text64: "Please enter transaction password",
        text65: "Fee:",
        text66: "Transfer Fee",
        text67: "Transfer Fee",
        text68: "The fee will be adjusted according to the market price fluctuation",
        text69: "Submit Application",
        text70: "Reminder:",
        text71: "1. The minimum withdrawal amount is (100 USDT, 0.05 ETH, 0.01 BTC).",
        text72: "2. BEP20 is expected to arrive in 2-10 minutes, TRC20 is 0.1-5 minutes, and ETH or BTC may take longer. Phone transfer is an internal transfer, fee-free and immediate.",
        text73: "3. USDT between different protocols cannot be transferred to each other, please confirm the type before transferring. Note: Addresses issued based on the BEP20 protocol start with 0x, while those based on the TRC20 protocol start with [T].",
        text74: "4. To ensure the security of your assets, it is recommended to apply for withdrawals on weekdays (09:00 - 18:00) and weekends (09:00 - 13:00). Withdrawals at other times will be delayed.",
        text75: "5. Please make sure the network environment is safe.",
        text76: "Select Area Code",
        text77: "Please enter withdrawal address",
        text78: "Please select area code",
        text79: "Please enter withdrawal phone number",
        text80: "Please enter withdrawal amount",
        text81: "Please enter transaction password",
        text82: "Submission Successful",
        text83: "Quick Buy Orders",
        text84: "Open",
        text85: "Close",
        text86: "Today's Transactions:",
        text87: "Search Orders",
        text88: "Expand",
        text89: "Collapse",
        text90: "Date:",
        text91: "Start",
        text92: "End",
        text93: "Order Number:",
        text94: "Please enter order number",
        text95: "Order Status:",
        text96: "Please select",
        text97: "Abnormal Status:",
        text98: "Refund Status:",
        text99: "Search",
        text100: "No Orders Yet",
        text101: "Order Number:",
        text102: "Order Information:",
        text103: "User",
        text104: "Quantity",
        text105: "Unit Price",
        text106: "Payment",
        text107: "Order Operation:",
        text108: "Confirm Transaction",
        text109: "View Payment Information",
        text110: "Copy Order",
        text111: "Payment Information",
        text112: "Pending Payment",
        text113: "Pending Confirmation",
        text114: "Customer Cancelled",
        text115: "Transaction Completed",
        text116: "Trader Cancelled",
        text117: "Timeout Cancelled",
        text118: "Platform Cancelled",
        text119: "Abnormal Under Review",
        text120: "Abnormal Review Rejected",
        text121: "Abnormal Review Approved",
        text122: "Enter Refund Information",
        text123: "Refunding",
        text124: "Refunded",
        text125: "Received",
        text126: "Not Received",
        text127: "Refund Failed",
        text128: "Platform Cancelled",
        text129: "Operation Successful",
        text130: "Copy Successful",
        text131: "Copy Failed",
        text132: "Quick Sell Orders",
        text133: "Daily Limit for Sell Orders:",
        text134: "Today's Transactions:",
        text135: "Receive Amount",
        text136: "Pay",
        text137: "Cancel",
        text138: "Confirm Payment",
        text139: "Cancellation Successful",
        text140: "Copy Successful",
        text141: "Copy Failed",
        text142: "Bank Card",
        text143: "Alipay",
        text144: "Card Number:",
        text145: "Cardholder:",
        text146: "Branch:",
        text147: "Used/Daily Limit:",
        text148: "Order Number/Above Limit:",
        text149: "Accept Orders:",
        text150: "Account",
        text151: "Holder:",
        text152: "Transaction Amount:",
        text153: "Accept Orders:",
        text154: "Add Bank Card",
        text155: "Add Alipay Account",
        text156: "Add HIBIT Account",
        text157: "Delete Account",
        text158: "Please contact superior to open Alipay binding permission",
        text159: "You have not yet completed real-name authentication, please submit relevant information",
        text160: "Real Name:",
        text161: "Passport Number:",
        text162: "Please enter real name",
        text163: "Please enter passport number",
        text164: "Passport Information Photo",
        text165: "Hold Passport Photo",
        text166: "Submit Application",
        text167: "Waiting for System Review",
        text168: "Real-name Success",
        text169: "Real-name Rejected",
        text170: "Reapply",
        text171: "Please upload photos",
        text172: "Please enter real name",
        text173: "Please enter passport number",
        text174: "Please upload passport photo",
        text175: "Please upload hold passport photo",
        text176: "Submission Successful, Awaiting Review",
        text177: "Deposit Type:",
        text178: "Deposit Address:",
        text179: "Deposit Address:",
        text180: "Copy Address",
        text181: "Reminder:",
        text182: "1. The minimum deposit amount is USDT-BEP2020: 20; USDT-TRC20: 1; BTC: 0.003; ETH: 0.05;",
        text183: "Amounts below the minimum will not be credited and cannot be refunded",
        text184: "2. BEP20 is expected to arrive in 2-10 minutes, TRC20 is 0.1-5 minutes, and may take longer in case of blockchain congestion",
        text185: "3. BEP20, TRC20 addresses are different and not interoperable; please carefully confirm the address type to avoid loss due to mis-transfer. Note: Addresses issued based on the BEP20 protocol start with [0x]; addresses based on the TRC20 protocol start with [T].",
        text186: "5. After copying and pasting the address, carefully verify that it matches completely with the address you copied, before depositing. The platform cannot assist in recovery if it does not match, please confirm again to avoid loss of important assets.",
        text187: "For any questions,",
        text188: "Bank Name:",
        text189: "Please enter bank name",
        text190: "Account Number:",
        text191: "Please enter account number",
        text192: "Cardholder:",
        text193: "Please enter cardholder name",
        text194: "Branch of Account:",
        text195: "Please enter branch of account",
        text196: "Bank Address:",
        text197: "Please enter bank address",
        text198: "Bank Code:",
        text199: "Please enter bank code",
        text200: "International Wire Code:",
        text201: "Please enter international wire code",
        text202: "Daily Maximum Receipt Amount:",
        text203: "Please enter daily maximum amount",
        text204: "Order Limit:",
        text205: "Please enter order limit",
        text206: "Is Open:",
        text207: "Payment Password:",
        text208: "Please enter payment password",
        text209: "Google Verification Code:",
        text210: "Please enter Google verification code",
        text211: "Confirm",
        text212: "Please enter bank name",
        text213: "Please enter account number",
        text214: "Please enter cardholder name",
        text215: "Please enter branch of account",
        text216: "Please enter bank address",
        text217: "Please enter bank code",
        text218: "Please enter international wire code",
        text219: "Please enter daily maximum amount",
        text220: "Please enter order limit",
        text221: "Please enter payment password",
        text222: "Please enter Google verification code",
        text223: "Account:",
        text224: "Please enter HIBIT account",
        text225: "Holder Name:",
        text226: "Please enter holder name",
        text227: "Receive QR Code:",
        text228: "Is Open:",
        text229: "Payment Password:",
        text230: "Please enter payment password",
        text231: "Google Verification Code:",
        text232: "Please enter Google verification code",
        text233: "Please upload receive QR code",
        text234: "Added Successfully",
        text235: "Account:",
        text236: "Please enter Alipay account",
        text237: "Search Area Code",
        text238: "Please select area code!",
        text239: "Receipt Type:",
        text240: "Receipt Account:",
        text241: "Receiver Name:",
        text242: "Bank Name:",
        text243: "Receipt Code:",
        text244: "Please upload payment voucher:",
        text245: "Confirm Payment",
        text246: "Please upload payment voucher",
        text247: "Payment Success",
        text248: "You are deleting the account, please complete the following verification",
        text249: "Confirm Delete",
        text250: "Delete Successful",
        text251: "Phone Transfer",
        text252: "Receive Address",
        text253: "Amount",
        text254: "Fee",
        text255: "Status",
        text256: "Time",
        text257: "Pending Review",
        text258: "Success",
        text259: "Failure",
        text260: "Order Number:",
        text261: "Please enter order number",
        text262: "Transaction Type:",
        text263: "Time:",
        text264: "Quantity",
        text265: "Total Amount",
        text266: "Time",
        text267: "Sell",
        text268: "Sell Cancel",
        text269: "Buy",
        text270: "Deposit",
        text271: "Withdrawal Deduction",
        text272: "Withdrawal Cancel",
        text273: "Withdrawal Miner Fee",
        text274: "Return Handling Fee",
        text275: "Margin",
        text276: "Margin Redemption",
        text277: "Gain",
        text278: "Please enter phone number",
        text279: "1 phone/password",
        text280: "2 Bind Google",
        text281: "3 Complete",
        text282: "Phone Number:",
        text283: "Login Password:",
        text284: "Please enter login password",
        text285: "Payment Password:",
        text286: "Please enter payment password",
        text287: "Verification Code:",
        text288: "Please enter SMS verification code",
        text289: "Next",
        text290: "Scan the QR code below to bind Google Authenticator",
        text291: "Google Verification Code:",
        text292: "Please enter verification code",
        text293: "Previous",
        text294: "Congratulations on completing registration",
        text295: "Complete Registration",
        text296: "Payment password format is 6 digits pure numbers",
        text297: "Failed to obtain Google verification code information",
        text298: "Please enter Google verification code",
        text299: "Registration failed",
        text300: "Please select area code",
        text301: "User already exists",
        text302: "Get a new validator",
        text303: "Bind",
        text304: "Success",
        text305: "Please enter new validation information",
        text306: "Binding Successful",
        text307: "New Login Password:",
        text308: "Please enter new login password",
        text309: "Confirm Password:",
        text310: "Please confirm new login password",
        text311: "Confirm Modification",
        text312: "The two passwords entered do not match",
        text313: "Modification Successful",
        text314: "New Payment Password: (Note: requires 6 digits pure numbers)",
        text315: "Please enter new payment password",
        text316: "Confirm Payment Password:",
        text317: "Please confirm payment password",
        text318: "Confirm Settings",
        text319: "Source",
        text320: "Amount",
        text321: "Status",
        text322: "Time",
        text323: "In Progress",
        text324: "Success",
        text325: "Failure",
        text326: "Language:",
        text327: "Modify limit",
        text328: "Please set a transaction limit:",
        text329: "Please enter the limit",
        text330: "Confirm",
        text331: "Online Wallet/Digital Wallet",
        text332: "Please enter account",
        text333: "Account type:",
        text334: "Email",
        text335: "Please enter email",
        text336: "Email:",
        text337: "Please enter the correct email address",
        text338: ":",
        text339: ":",
        text340: ":",
        text341: ":",
        text342: "Abnormal",
        text343: "Exception type:",
        text344: "Non personal real name payment",
        text345: "Inconsistent payment amount",
        text346: "Payment not received",
        text347: "Refund quantity:",
        text348: "Please enter the refund quantity",
        text349: "Please select the type of exception",
        text350: "Clean",
        text351: "Incorrect name format, preserved in uppercase letters",
        text352: "Beep",
        text353: "Payment account",
        text354: "Payment method",
        text355: "Payment account",
        text356: "Payer",
        text357: "Receiving account",
        text358: "Payment method",
        text359: "Receiving account",
        text360: 'Payee',
        text361: "Reason for rejection:",
        text362: "Patch Money",
        text363: "Supplementary payment upload voucher",
    }
}