export default {
    m: {
        text1: "Connexion au compte",
        text2: "S'inscrire",
        text3: "Mot de passe login",
        text4: "Mobile",
        text5: "Indicatif régional",
        text6: "Veuillez entrer votre nom d'utilisateur",
        text7: "Veuillez entrer votre mot de passe",
        text8: "Veuillez entrer le code de vérification du téléphone",
        text9: "Connexion",
        text10: "S'inscrire",
        text11: "Choisir l'indicatif régional",
        text12: "Veuillez choisir l'indicatif régional",
        text13: "Veuillez entrer votre numéro de téléphone",
        text14: "Veuillez entrer votre mot de passe",
        text15: "Veuillez entrer le code de vérification du téléphone",
        text16: "Veuillez entrer le code de vérification Google",
        text17: "Chargement en cours...",
        text18: "Nom d'utilisateur:",
        text19: "Dépôt de garantie:",
        text20: "Payer",
        text21: "Référence Acheter:",
        text22: "Référence Vendu:",
        text23: "Sécurité:",
        text24: "Passe/code",
        text25: "Compte:",
        text26: "Nom réel",
        text27: "Paiement",
        text28: "Déconnexion",
        text29: "Mes avoirs",
        text30: "Dépôt",
        text31: "Retrait",
        text32: "Détails",
        text33: "Paiement de la garantie",
        text34: "Non certifié",
        text35: "Certifié",
        text36: "En audit",
        text37: "L'échec",
        text38: "Messages de dépôt",
        text39: "Messages de retrait",
        text40: "Vérification de l'identité en cours",
        text41: "Ordre de vente rapide",
        text42: "Ordre d'achat rapide",
        text43: "Cette cryptomonnaie n'est pas actuellement prise en charge",
        text44: "Historique des retraits",
        text45: "Historique des dépôts",
        text46: "Historique des transactions",
        text47: "Modifier le mot de passe de connexion",
        text48: "Modifier le mot de passe de paiement",
        text49: "Modifier le vérificateur Google",
        text50: "Mon solde :",
        text51: "Dépôt de garantie :",
        text52: "Confirmer le paiement",
        text53: "Solde insuffisant",
        text54: "Paiement réussi",
        text55: "Type de transfert :",
        text56: "Numéro de téléphone",
        text57: "Adresse de réception :",
        text58: "Veuillez entrer l'adresse de réception",
        text59: "Numéro de téléphone de réception :",
        text60: "Veuillez entrer le numéro de téléphone de réception",
        text61: "Montant à transférer :",
        text62: "Veuillez entrer le montant à transférer",
        text63: "Mot de passe de transaction :",
        text64: "Veuillez entrer le mot de passe de transaction",
        text65: "Frais :",
        text66: "Frais de transfert",
        text67: "Frais de transfert",
        text68: "Les frais seront ajustés en fonction de la fluctuation des prix du marché",
        text69: "Soumettre la demande",
        text70: "Conseil chaleureux :",
        text71: "1. Le montant minimum de retrait est de (100 USDT, 0.05 ETH, 0.01 BTC).",
        text72: "2. Le temps de traitement prévu est de 2 à 10 minutes pour BEP20, de 0.1 à 5 minutes pour TRC20, et peut être plus long pour ETH ou BTC. Les transferts entre téléphones sont des transferts internes, sans frais et instantanés.",
        text73: "3. Les USDT basés sur différents protocoles ne peuvent pas être transférés entre eux. Veuillez confirmer le type avant de transférer. Remarque : Les adresses émises selon le protocole BEP20 commencent par 0x, tandis que celles émises selon le protocole TRC20 commencent par T.",
        text74: "4. Pour assurer la sécurité de vos avoirs, il est recommandé de demander un retrait pendant les jours ouvrables (09:00 - 18:00) et les week-ends (09:00 - 13:00). Les retraits effectués en dehors de ces horaires seront retardés.",
        text75: "5. Assurez-vous de confirmer que l'environnement réseau est sécurisé.",
        text76: "Choisir l'indicatif régional",
        text77: "Veuillez entrer l'adresse de réception",
        text78: "Veuillez choisir l'indicatif régional",
        text79: "Veuillez entrer le numéro de téléphone de réception",
        text80: "Veuillez entrer le montant à transférer",
        text81: "Veuillez entrer le mot de passe de transaction",
        text82: "Succès de la soumission",
        text83: "Ordre d'achat rapide",
        text84: "Ouvrir",
        text85: "Fermer",
        text86: "Clôture:",
        text87: "Recherche de commande",
        text88: "Développer",
        text89: "Réduire",
        text90: "Date :",
        text91: "Commencer",
        text92: "Terminer",
        text93: "Numéro de commande :",
        text94: "Veuillez entrer le numéro de commande",
        text95: "Statut de la commande :",
        text96: "Veuillez sélectionner",
        text97: "Statut anormal :",
        text98: "Statut de remboursement :",
        text99: "Rechercher",
        text100: "Aucune commande pour le moment",
        text101: "Numéro de commande :",
        text102: "Informations de commande :",
        text103: "Utilisateur",
        text104: "Quantité",
        text105: "Prix unitaire",
        text106: "Payé",
        text107: "Opération de commande :",
        text108: "Confirmer la transaction",
        text109: "Voir les informations de paiement",
        text110: "Copier commande",
        text111: "Informations de paiement",
        text112: "En attente de paiement",
        text113: "En attente de confirmation",
        text114: "Annulé par le client",
        text115: "Transaction terminée",
        text116: "Annulé par le commerçant",
        text117: "Annulé en raison d'un dépassement de temps",
        text118: "Annulé par la plateforme",
        text119: "En attente d'une vérification anormale",
        text120: "Vérification anormale refusée",
        text121: "Vérification anormale réussie",
        text122: "Entrer les informations de remboursement",
        text123: "En cours de remboursement",
        text124: "Remboursé",
        text125: "Reçu",
        text126: "Non reçu",
        text127: "Échec du remboursement",
        text128: "Annulé par la plateforme",
        text129: "Opération réussie",
        text130: "Copie réussie",
        text131: "Échec de la copie",
        text132: "Ordre de vente rapide",
        text133: "Limites:",
        text134: "Clôture:",
        text135: "Montant reçu",
        text136: "Paiement",
        text137: "Annuler la commande",
        text138: "Confirmer le paiement",
        text139: "Annulation réussie",
        text140: "Copie réussie",
        text141: "Échec de la copie",
        text142: "Carte bancaire",
        text143: "Alipay",
        text144: "Numéro de carte :",
        text145: "Titulaire de la carte :",
        text146: "Agence :",
        text147: "Utilisé/quotidien :",
        text148: "Nombre de commandes/limite :",
        text149: "Accepter les commandes :",
        text150: "Nom du compte",
        text151: "Titulaire :",
        text152: "Montant de la transaction :",
        text153: "Accepter les commandes :",
        text154: "Ajouter une carte bancaire",
        text155: "Ajouter un compte Alipay",
        text156: "Ajouter un compte HIBIT",
        text157: "Supprimer le compte",
        text158: "Veuillez contacter le supérieur pour ouvrir les autorisations de liaison Alipay",
        text159: "Vous n'avez pas encore été certifié. Veuillez soumettre les informations pertinentes",
        text160: "Nom réel :",
        text161: "Numéro de passeport :",
        text162: "Veuillez entrer votre nom réel",
        text163: "Veuillez entrer le numéro de passeport",
        text164: "Photo de passeport",
        text165: "Photo tenant le passeport",
        text166: "Soumettre la demande",
        text167: "En attente de vérification du système",
        text168: "Certification réussie",
        text169: "Certification refusée",
        text170: "Soumettre à nouveau",
        text171: "Veuillez télécharger les images",
        text172: "Veuillez entrer votre nom réel",
        text173: "Veuillez entrer le numéro de passeport",
        text174: "Veuillez télécharger la photo du passeport",
        text175: "Veuillez télécharger la photo tenant le passeport",
        text176: "Soumission réussie, en attente de vérification",
        text177: "Type de dépôt :",
        text178: "Adresse de dépôt :",
        text179: "Adresse de dépôt :",
        text180: "Copier l'adresse",
        text181: "Conseil chaleureux :",
        text182: "1. Le montant minimum de dépôt est de USDT-BEP2020 : 20 ; USDT-TRC20 : 1 ; BTC : 0.003 ; ETH : 0.05 ;",
        text183: "Les dépôts inférieurs au montant minimum ne seront ni crédités ni remboursés",
        text184: "2. Le temps de traitement prévu est de 2 à 10 minutes pour BEP20, de 0.1 à 5 minutes pour TRC20. En cas de congestion de la chaîne, ce délai peut être prolongé",
        text185: "3. Les adresses BEP20 et TRC20 sont différentes et non interopérables ; veuillez vérifier attentivement le type d'adresse pour éviter les erreurs de transfert. Remarque : Les adresses émises selon le protocole BEP20 commencent par 0x, tandis que celles émises selon le protocole TRC20 commencent par T.",
        text186: "5. Après avoir copié et collé l'adresse, veuillez vérifier attentivement que l'adresse que vous avez copiée correspond exactement à l'adresse avant, au milieu et après le virement. Si elles ne correspondent pas, la plateforme ne pourra pas vous aider à récupérer les fonds. Veuillez vérifier à nouveau pour éviter toute perte d'actifs importants.",
        text187: "Si vous avez des questions,",
        text188: "Nom de la banque :",
        text189: "Veuillez entrer le nom de la banque",
        text190: "Numéro de compte :",
        text191: "Veuillez entrer le numéro de compte",
        text192: "Titulaire de la carte :",
        text193: "Veuillez entrer le nom du titulaire de la carte",
        text194: "Agence :",
        text195: "Veuillez entrer l'agence",
        text196: "Adresse de la banque :",
        text197: "Veuillez entrer l'adresse de la banque",
        text198: "Numéro de banque :",
        text199: "Veuillez entrer le numéro de banque",
        text200: "Code de transfert international :",
        text201: "Veuillez entrer le code de transfert international",
        text202: "Limite de réception quotidienne maximale :",
        text203: "Veuillez entrer la limite quotidienne maximale",
        text204: "Limite de commandes :",
        text205: "Veuillez entrer la limite de commandes",
        text206: "Ouvrir ou fermer :",
        text207: "Mot de passe de paiement :",
        text208: "Veuillez entrer le mot de passe de paiement",
        text209: "Code de vérification Google :",
        text210: "Veuillez entrer le code de vérification Google",
        text211: "Confirmer",
        text212: "Veuillez entrer le nom de la banque",
        text213: "Veuillez entrer le numéro de compte",
        text214: "Veuillez entrer le nom du titulaire de la carte",
        text215: "Veuillez entrer l'agence",
        text216: "Veuillez entrer l'adresse de la banque",
        text217: "Veuillez entrer le numéro de banque",
        text218: "Veuillez entrer le code de transfert international",
        text219: "Veuillez entrer la limite quotidienne maximale",
        text220: "Veuillez entrer la limite de commandes",
        text221: "Veuillez entrer le mot de passe de paiement",
        text222: "Veuillez entrer le code de vérification Google",
        text223: "Compte :",
        text224: "Veuillez entrer le compte HIBIT",
        text225: "Nom du titulaire :",
        text226: "Veuillez entrer le nom du titulaire",
        text227: "Code QR de réception :",
        text228: "Ouvrir ou fermer :",
        text229: "Mot de passe de paiement :",
        text230: "Veuillez entrer le mot de passe de paiement",
        text231: "Code de vérification Google :",
        text232: "Veuillez entrer le code de vérification Google",
        text233: "Veuillez télécharger le code QR de réception",
        text234: "Ajout réussi",
        text235: "Compte :",
        text236: "Veuillez entrer le compte Alipay",
        text237: "Rechercher le code régional",
        text238: "Veuillez sélectionner le code régional !",
        text239: "Type de réception :",
        text240: "Compte de réception :",
        text241: "Nom du destinataire :",
        text242: "Nom de la banque :",
        text243: 'Code de réception :',
        text244: "Veuillez télécharger le reçu de paiement :",
        text245: "Confirmer le paiement",
        text246: "Veuillez télécharger le reçu de paiement",
        text247: "Paiement réussi",
        text248: "Vous êtes en train de supprimer le compte, veuillez effectuer la vérification suivante",
        text249: "Confirmer la suppression",
        text250: "Suppression réussie",
        text251: "Transfert par téléphone",
        text252: "Adresse de réception",
        text253: "Montant",
        text254: "Frais",
        text255: "Statut",
        text256: "Temps",
        text257: "En attente de vérification",
        text258: "Succès",
        text259: "Échec",
        text260: "Numéro de commande :",
        text261: "Veuillez entrer le numéro de commande",
        text262: "Type de transaction :",
        text263: "Heure :",
        text264: "Quantité",
        text265: "Total",
        text266: "Temps",
        text267: "Vendre",
        text268: "Annuler la vente",
        text269: "Acheter",
        text270: "Dépôt",
        text271: "Frais de retrait",
        text272: "Annuler le retrait",
        text273: "Frais de retrait",
        text274: "Rembourser les frais",
        text275: "Dépôt de garantie",
        text276: "Rachat de garantie",
        text277: "Obtenir",
        text278: "Veuillez entrer le numéro de téléphone",
        text279: "1 Compte",
        text280: "2 Google",
        text281: "3 La fin",
        text282: "Numéro de téléphone:",
        text283: "Mot de passe de connexion:",
        text284: "Veuillez entrer le mot de passe de connexion",
        text285: "Mot de passe de paiement:",
        text286: "Veuillez entrer le mot de passe de paiement",
        text287: "Code de vérification :",
        text288: "Veuillez entrer le code de vérification SMS",
        text289: "Prochaine étape",
        text290: "Scannez le code QR ci-dessous pour lier Google Authenticator",
        text291: "Code de vérification Google :",
        text292: "Veuillez entrer le code de vérification",
        text293: "Précédent",
        text294: "Félicitations pour votre inscription terminée",
        text295: "Inscription terminée",
        text296: "Le format du mot de passe de paiement est de 6 chiffres",
        text297: "Échec de l'obtention des informations de vérification Google Authenticator",
        text298: "Veuillez entrer le code de vérification Google",
        text299: "Échec de l'inscription",
        text300: "Veuillez sélectionner le code régional",
        text301: "Cet utilisateur existe déjà",
        text302: "Obtenir un nouvel authentificateur",
        text303: "Lier",
        text304: "Obtention réussie",
        text305: "Veuillez entrer de nouvelles informations d'authentification",
        text306: "Liaison réussie",
        text307: "Nouveau mot de passe de connexion :",
        text308: "Veuillez entrer un nouveau mot de passe de connexion",
        text309: "Confirmer le mot de passe :",
        text310: "Veuillez confirmer le nouveau mot de passe de connexion",
        text311: "Confirmer la modification",
        text312: "Les deux mots de passe saisis sont différents",
        text313: "Modification réussie",
        text314: "Nouveau mot de passe de paiement : (Note: format de 6 chiffres)",
        text315: "Veuillez entrer un nouveau mot de passe de paiement",
        text316: "Confirmer le mot de passe de paiement :",
        text317: "Veuillez confirmer le nouveau mot de passe de paiement",
        text318: "Confirmer les paramètres",
        text319: "Source",
        text320: "Montant",
        text321: "Statut",
        text322: "Temps",
        text323: "En cours de traitement",
        text324: "Succès",
        text325: "Échec",
        text326: "Langue:",
        text327: "Modifier la limite",
        text328: "Veuillez définir une limite de transaction:",
        text329: "Veuillez entrer la limite",
        text330: "Déterminer",
        text331: "Ligne / Numérique",
        text332: "Veuillez entrer le numéro de compte",
        text333: "Type de compte:",
        text334: "Email",
        text335: "Veuillez entrer une boîte aux lettres",
        text336: "Email:",
        text337: "Veuillez entrer une adresse e - mail correcte",
        text338: ":",
        text339: ":",
        text340: ":",
        text341: ":",
        text342: "Anomalies",
        text343: "Type d'anomalie:",
        text344: "Paiement sans nom réel",
        text345: "Montant des paiements incohérent",
        text346: "Paiement non reçu",
        text347: "Nombre de remboursements:",
        text348: "Veuillez entrer le nombre de remboursements",
        text349: "Veuillez sélectionner un type d'exception",
        text350: "Nettoyé",
        text351: "Format incorrect du nom, sauvé en majuscules",
        text352: "Tip",
        text353: "Compte paiement",
        text354: "Mode de paiement",
        text355: "Numéro de compte de paiement",
        text356: "Le payeur",
        text357: "Compte de collecte",
        text358: "Mode de collecte",
        text359: "Numéro",
        text360: 'Le bénéficiaire',
        text361: "Raisons du refus",
    }
}