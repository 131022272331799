export default {
    m: {
        text1: "アカウントログイン",
        text2: "アカウント登録",
        text3: "パスワード",
        text4: "電話番号",
        text5: "地域コード",
        text6: "ログインアカウントを入力してください",
        text7: "ログインパスワードを入力してください",
        text8: "携帯電話認証コードを入力してください",
        text9: "ログイン",
        text10: "アカウント登録",
        text11: "地域コードを選択してください",
        text12: "地域コードを選択してください",
        text13: "ログイン携帯番号を入力してください",
        text14: "ログインパスワードを入力してください",
        text15: "携帯電話認証コードを入力してください",
        text16: "Google認証コードを入力してください",
        text17: "読み込み中...",
        text18: "アカウント：",
        text19: "保証金：",
        text20: "支払う",
        text21: "参考買値：",
        text22: "参考売値：",
        text23: "セキュリティ設定：",
        text24: "パスワード/認証コードの管理",
        text25: "アカウント設定：",
        text26: "実名認証",
        text27: "支払い管理",
        text28: "ログアウト",
        text29: "私の資産",
        text30: "きおく",
        text31: "引き出し",
        text32: "資産記録",
        text33: "保証金の支払い",
        text34: "未実名",
        text35: "実名成功",
        text36: "実名中",
        text37: "実名失敗",
        text38: "入金メッセージ",
        text39: "引き出しメッセージ",
        text40: "実名審査",
        text41: "クイックセル注文",
        text42: "クイックバイ注文",
        text43: "この通貨は一時的にサポートされていません",
        text44: "引き出し記録",
        text45: "入金記録",
        text46: "取引記録",
        text47: "ログインパスワードの変更",
        text48: "支払いパスワードの変更",
        text49: "Google認証器の変更",
        text50: "私の残高：",
        text51: "保証金：",
        text52: "支払いを確定する",
        text53: "残高が不足しています",
        text54: "支払いが成功しました",
        text55: "転出タイプ：",
        text56: "携帯電話番号",
        text57: "受け取りアドレス：",
        text58: "受け取りアドレスを入力してください",
        text59: "受け取り携帯番号：",
        text60: "受け取り携帯番号を入力してください",
        text61: "転出数量：",
        text62: "転出数量を入力してください",
        text63: "取引パスワード：",
        text64: "取引パスワードを入力してください",
        text65: "手数料：",
        text66: "転出手数料",
        text67: "転出手数料",
        text68: "手数料は市場価格の変動に応じて随時調整されます",
        text69: "申請を送信する",
        text70: "注意事項：",
        text71: "1. 最小引き出し額は(100USDT、0.05ETH、0.01BTC)です。",
        text72: "2. BEP20の取引は2-10分、TRC20は0.1-5分で完了します。ETHやBTCの取引は時間がかかる場合があります。携帯電話番号間の取引は手数料が無料で、即時に処理されます。",
        text73: "3. 異なるプロトコル間でのUSDTの相互転送はできません。転送前にプロトコルを確認してください。なお、BEP20プロトコルのアドレスは0xで始まり、TRC20プロトコルのアドレスは[T]で始まります。",
        text74: "4. 資産の安全性を確保するために、平日(09:00 - 18:00)や週末(09:00 - 13:00)に引き出しを行うことをお勧めします。その他の時間帯の引き出しには遅延が発生する場合があります。",
        text75: "5. ネットワーク環境の安全性を確認してください。",
        text76: "地域コードを選択してください",
        text77: "転出先アドレスを入力してください",
        text78: "地域コードを選択してください",
        text79: "転出先携帯番号を入力してください",
        text80: "転出数量を入力してください",
        text81: "取引パスワードを入力してください",
        text82: "申請が送信されました",
        text83: "クイックバイ注文",
        text84: "オープン",
        text85: "クローズ",
        text86: "今日の取引：",
        text87: "注文を検索する",
        text88: "展開",
        text89: "折りたたむ",
        text90: "日付：",
        text91: "開始",
        text92: "終了",
        text93: "注文番号：",
        text94: "注文番号を入力してください",
        text95: "注文状態：",
        text96: "選択してください",
        text97: "異常状態：",
        text98: "返金状態：",
        text99: "検索",
        text100: "注文がありません",
        text101: "注文番号：",
        text102: "注文情報：",
        text103: "ユーザー",
        text104: "取引数量",
        text105: "単価",
        text106: "支払金額",
        text107: "注文操作：",
        text108: "取引を確認する",
        text109: "支払情報を確認する",
        text110: "注文番号をコピー",
        text111: "支払情報",
        text112: "未支払い",
        text113: "未確認",
        text114: "ユーザーキャンセル",
        text115: "取引完了",
        text116: "取引者キャンセル",
        text117: "タイムアウトキャンセル",
        text118: "システムキャンセル",
        text119: "異常審査中",
        text120: "異常審査却下",
        text121: "異常審査合格",
        text122: "返金情報を入力する",
        text123: "返金中",
        text124: "返金済み",
        text125: "受取済み",
        text126: "未受取",
        text127: "返金失敗",
        text128: "プラットフォームキャンセル",
        text129: "操作成功",
        text130: "コピー成功",
        text131: "コピー失敗",
        text132: "クイックセル注文",
        text133: "一日の売上上限：",
        text134: "本日の取引：",
        text135: "受取金額",
        text136: "支払い",
        text137: "注文をキャンセル",
        text138: "支払いを確認",
        text139: "キャンセル成功",
        text140: "コピー成功",
        text141: "コピー失敗",
        text142: "銀行カード",
        text143: "アリペイ",
        text144: "カード番号：",
        text145: "カード所有者：",
        text146: "支店：",
        text147: "使用済み/本日の限度額：",
        text148: "受注数/上限：",
        text149: "受注する：",
        text150: "アカウント",
        text151: "保有者：",
        text152: "取引金額：",
        text153: "受注する：",
        text154: "銀行カードを追加",
        text155: "アリペイアカウントを追加",
        text156: "HIBITアカウントを追加",
        text157: "アカウントを削除",
        text158: "上位者にアリペイバインディング権限の開通を依頼してください",
        text159: "まだ実名認証が完了していません。関連情報を提出してください",
        text160: "本名：",
        text161: "パスポート番号：",
        text162: "本名を入力してください",
        text163: "パスポート番号を入力してください",
        text164: "パスポート情報の写真",
        text165: "パスポートを持っている写真",
        text166: "申請を送信する",
        text167: "システム審査待ち",
        text168: "実名認証成功",
        text169: "実名認証拒否",
        text170: "再申請",
        text171: "画像をアップロードしてください",
        text172: "本名を入力してください",
        text173: "パスポート番号を入力してください",
        text174: "パスポートの写真をアップロードしてください",
        text175: "パスポートを持っている写真をアップロードしてください",
        text176: "申請が送信されました、審査待ちです",
        text177: "入金タイプ：",
        text178: "入金アドレス：",
        text179: "入金アドレス：",
        text180: "アドレスをコピー",
        text181: "注意事項：",
        text182: "1. 最小入金額はUSDT-BEP20: 20、USDT-TRC20: 1、BTC: 0.003、ETH: 0.05です。最小額未満の入金は処理されず、返金もされません。",
        text183: "2. BEP20の入金は2-10分、TRC20は0.1-5分で完了します。ただし、ブロックチェーンの混雑状況によって処理に時間がかかる場合があります。",
        text184: "3. BEP20、TRC20アドレスは異なり、相互接続されません。アドレスのタイプをよく確認してください。なお、BEP20プロトコルのアドレスは[0x]で始まり、TRC20プロトコルのアドレスは[T]で始まります。",
        text185: "5. アドレスをコピーして貼り付ける際に、完全に一致していることを確認してから入金してください。不一致の場合、プラットフォームは補償できませんので、再度確認してください。",
        text186: "ご質問がある場合は、",
        text187: "銀行名：",
        text188: "銀行名を入力してください",
        text189: "アカウント番号：",
        text190: "口座番号：",
        text191: "口座番号を入力してください",
        text192: "カード保有者：",
        text193: "カード保有者の名前を入力してください",
        text194: "開設支店：",
        text195: "開設支店を入力してください",
        text196: "銀行の住所：",
        text197: "銀行の住所を入力してください",
        text198: "銀行コード：",
        text199: "銀行コードを入力してください",
        text200: "国際送金コード：",
        text201: "国際送金コードを入力してください",
        text202: "1日の最大受け取り額：",
        text203: "1日の最大額を入力してください",
        text204: "受け取り上限：",
        text205: "受け取り上限を入力してください",
        text206: "オープンする：",
        text207: "支払いパスワード：",
        text208: "支払いパスワードを入力してください",
        text209: "Google認証コード：",
        text210: "Google認証コードを入力してください",
        text211: "確定",
        text212: "銀行名を入力してください",
        text213: "口座番号を入力してください",
        text214: "カード保有者の名前を入力してください",
        text215: "開設支店を入力してください",
        text216: "銀行の住所を入力してください",
        text217: "銀行コードを入力してください",
        text218: "国際送金コードを入力してください",
        text219: "1日の最大額を入力してください",
        text220: "受け取り上限を入力してください",
        text221: "支払いパスワードを入力してください",
        text222: "Google認証コードを入力してください",
        text223: "アカウント：",
        text224: "HIBITアカウントを入力してください",
        text225: "保有者の名前：",
        text226: "保有者の名前を入力してください",
        text227: "受け取り用のQRコード：",
        text228: "オープンする：",
        text229: "支払いパスワード：",
        text230: "支払いパスワードを入力してください",
        text231: "Google認証コード：",
        text232: "Google認証コードを入力してください",
        text233: "受取QRコードをアップロードしてください",
        text234: "追加成功",
        text235: "アカウント：",
        text236: "アリペイアカウントを入力してください",
        text237: "エリアコードを検索",
        text238: "エリアコードを選択してください！",
        text239: "受取タイプ：",
        text240: "受取アカウント：",
        text241: "受取人の名前：",
        text242: "銀行名：",
        text243: "受取コード：",
        text244: "支払い証明書をアップロードしてください：",
        text245: "支払いを確認",
        text246: "支払い証明書をアップロードしてください",
        text247: "支払い成功",
        text248: "アカウントを削除しています。以下の確認を完了してください",
        text249: "削除を確認",
        text250: "削除成功",
        text251: "携帯電話からの転送",
        text252: "受取アドレス",
        text253: "金額",
        text254: "手数料",
        text255: "ステータス",
        text256: "時間",
        text257: "審査待ち",
        text258: "成功",
        text259: "失敗",
        text260: "注文番号：",
        text261: "注文番号を入力してください",
        text262: "取引タイプ：",
        text263: "時間：",
        text264: "数量",
        text265: "合計",
        text266: "時間",
        text267: "売り",
        text268: "売りをキャンセル",
        text269: "買い",
        text270: "入金",
        text271: "出金引き落とし",
        text272: "出金をキャンセル",
        text273: "出金手数料",
        text274: "手数料を返金",
        text275: "保証金",
        text276: "保証金償還",
        text277: "取得",
        text278: "携帯電話番号を入力してください",
        text279: "1. 携帯電話/鍵",
        text280: "2. Google",
        text281: "3. 完了",
        text282: "携帯電話番号：",
        text283: "ログインパスワード：",
        text284: "ログインパスワードを入力してください",
        text285: "支払いパスワード：",
        text286: "支払いパスワードを入力してください",
        text287: "確認コード：",
        text288: "SMS確認コードを入力してください",
        text289: "次へ",
        text290: "下記のQRコードをスキャンしてGoogle認証器をバインドしてください",
        text291: "Google認証コード：",
        text292: "認証コードを入力してください",
        text293: "前のステップ",
        text294: "おめでとうございます、登録が完了しました",
        text295: "登録を完了する",
        text296: "支払いパスワードの形式は6桁の数字です",
        text297: "Google認証コード情報の取得に失敗しました",
        text298: "Google認証コードを入力してください",
        text299: "登録に失敗しました",
        text300: "エリアコードを選択してください",
        text301: "このユーザーは既に存在します",
        text302: "新しい認証器を取得する",
        text303: "バインド",
        text304: "取得成功",
        text305: "新しい認証情報を入力してください",
        text306: "バインド成功",
        text307: "新しいログインパスワード：",
        text308: "新しいログインパスワードを入力してください",
        text309: "パスワードの確認：",
        text310: "新しいログインパスワードを確認してください",
        text311: "変更を確認する",
        text312: "2回のパスワード入力が一致しません",
        text313: "変更成功",
        text314: "新しい支払いパスワード：（注意：6桁の数字で構成されている必要があります）",
        text315: "新しい支払いパスワードを入力してください",
        text316: "支払いパスワードの確認：",
        text317: "支払いパスワードを確認してください",
        text318: "設定を確認する",
        text319: "出典",
        text320: "金額",
        text321: "ステータス",
        text322: "時間",
        text323: "処理中",
        text324: "成功",
        text325: "失敗",
        text326: "言語設定：",
        text327: "限度額の変更",
        text328: "引限度額を設定してください：",
        text329: "限度額を入力してください",
        text330: "を選択して、",
        text331: "オンラインウォレット/デジタルウォレット",
        text332: "アカウントを入力してください",
        text333: "の種類：",
        text334: "Email",
        text335: "Emailを入力してください",
        text336: "Email:",
        text337: "正しいメールアドレスを入力してください",
        text338: ":",
        text339: ":",
        text340: ":",
        text341: ":",
        text342: "例外処理",
        text343: "例外のタイプ：",
        text344: "本人以外の実名支払い",
        text345: "支払金額が一致しない",
        text346: "支払が受信されていません",
        text347: "払戻数量：",
        text348: "返金数量を入力してください",
        text349: "例外タイプを選択してください",
        text350: "空",
        text351: "名前の書式が正しくないので、大文字にしてください",
        text352: "ヒント",
        text353: "支払口座",
        text354: "支払い方法",
        text355: "支払い口座",
        text356: "支払人",
        text357: "入金口座",
        text358: "入金方法",
        text359: "入金アカウント",
        text360: '受取人',
        text361: "拒否理由"
    }
}