import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import langEn from 'element-ui/lib/locale/lang/en'
import locale from 'element-ui/lib/locale'

import publicScript from "./assets/script/public";
Vue.use(publicScript);
import "./assets/style/public.scss";

import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';

locale.use(langEn);

Vue.use(ElementUI);

import VueClipboard from 'vue-clipboard2';
Vue.use(VueClipboard);

// 引入多语言
import VueI18n from 'vue-i18n';
// 引入语言包
import zh_HK from './assets/lan/zh_HK.js';  // 香港
import zh_TW from './assets/lan/zh_TW.js';  // 台湾
import en_US from './assets/lan/en_US.js';  // 英语
import ja_Jp from './assets/lan/ja_Jp.js';  // 日语
import ko_KR from './assets/lan/ko_KR.js';  // 韩语
import fr_FR from './assets/lan/fr_FR.js';  // 法语

let currLan = localStorage.getItem("lan");

if(!currLan) {
  currLan = "zh_TW";
  localStorage.setItem("lan","zh_TW");
}

// 注册多语言到Vue
Vue.use(VueI18n);

// 创建多语言实例
const i18n = new VueI18n({
	locale: currLan,
	messages: {
		'zh_HK':zh_HK,
    'zh_TW': zh_TW,
    'en_US': en_US,
    'ja_Jp': ja_Jp,
    'ko_KR': ko_KR,
    'fr_FR': fr_FR
	}
});

// 将多语言包挂载到原型链上
Vue.prototype._i18n = i18n;

Vue.config.productionTip = false

new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')
