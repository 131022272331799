export default {
    m: {
        text1: "계정 로그인",
        text2: "회원 가입",
        text3: "계정 비밀번호",
        text4: "전화기",
        text5: "지역 코드",
        text6: "로그인 계정을 입력하세요",
        text7: "로그인 비밀번호를 입력하세요",
        text8: "핸드폰 인증 코드를 입력하세요",
        text9: "로그인",
        text10: "회원 가입",
        text11: "지역 코드 선택",
        text12: "지역 코드를 선택하세요",
        text13: "로그인 핸드폰 번호를 입력하세요",
        text14: "로그인 비밀번호를 입력하세요",
        text15: "핸드폰 인증 코드를 입력하세요",
        text16: "Google 인증 코드를 입력하세요",
        text17: "로딩 중...",
        text18: "계정:",
        text19: "보증금:",
        text20: "납부",
        text21: "참고 구매 가격:",
        text22: "참고 판매 가격:",
        text23: "보안 설정:",
        text24: "비밀번호/인증 코드 관리",
        text25: "계정 설정:",
        text26: "실명 인증",
        text27: "결제 관리",
        text28: "로그아웃",
        text29: "내 자산",
        text30: "보관",
        text31: "인출",
        text32: "자산 기록",
        text33: "보증금 납부",
        text34: "미인증",
        text35: "인증 성공",
        text36: "인증 중",
        text37: "인증 실패",
        text38: "입금 메시지",
        text39: "출금 메시지",
        text40: "인증 검토",
        text41: "빠른 판매 주문",
        text42: "빠른 구매 주문",
        text43: "해당 통화 지원하지 않음",
        text44: "출금 기록",
        text45: "입금 기록",
        text46: "거래 기록",
        text47: "로그인 비밀번호 변경",
        text48: "결제 비밀번호 변경",
        text49: "Google 인증기 변경",
        text50: "내 잔액:",
        text51: "보증금:",
        text52: "납부 확인",
        text53: "잔액 부족",
        text54: "납부 성공",
        text55: "인출 유형:",
        text56: "핸드폰 번호",
        text57: "수령 주소:",
        text58: "수령 주소를 입력하세요",
        text59: "수령 핸드폰 번호:",
        text60: "수령 핸드폰 번호를 입력하세요",
        text61: "이체 금액:",
        text62: "이체 금액을 입력하세요",
        text63: "거래 비밀번호:",
        text64: "거래 비밀번호를 입력하세요",
        text65: "수수료:",
        text66: "이체 수수료",
        text67: "이체 수수료",
        text68: "수수료는 시장 가격 변동에 따라 언제든지 조정될 수 있습니다",
        text69: "신청 제출",
        text70: "안내:",
        text71: "1. 최소 인출 금액은 (100USDT, 0.05ETH, 0.01BTC)입니다.",
        text72: "2. BEP20는 예상 입금 시간이 2-10분이며, TRC20는 0.1-5분이며, ETH 또는 BTC는 더 오랜 시간이 소요될 수 있습니다. 핸드폰 번호 이체는 내부 이체로 수수료가 없으며 즉시 처리됩니다.",
        text73: "3. 서로 다른 프로토콜에 따라 USDT를 상호 이체할 수 없습니다. 이체하기 전에 프로토콜 유형을 확인하십시오. 참고: BEP20 프로토콜을 기반으로 한 주소는 0x로 시작하고, TRC20 프로토콜을 기반으로 한 주소는 [T]로 시작합니다.",
        text74: "4. 자산 안전을 위해 평일(09:00 - 18:00) 및 주말(09:00 - 13:00)에 인출을 권장하며, 그 외 시간에는 인출이 지연될 수 있습니다.",
        text75: "5. 네트워크 환경이 안전한지 반드시 확인하십시오.",
        text76: "지역 코드 선택",
        text77: "이체 주소를 입력하세요",
        text78: "지역 코드를 선택하세요",
        text79: "핸드폰 번호를 입력하세요",
        text80: "이체 금액을 입력하세요",
        text81: "거래 비밀번호를 입력하세요",
        text82: "제출 성공",
        text83: "빠른 구매 주문",
        text84: "열기",
        text85: "닫기",
        text86: "오늘 거래량:",
        text87: "주문 검색",
        text88: "펼치기",
        text89: "접기",
        text90: "날짜:",
        text91: "시작",
        text92: "종료",
        text93: "주문 번호:",
        text94: "주문 번호를 입력하세요",
        text95: "주문 상태:",
        text96: "선택하세요",
        text97: "이상 상태:",
        text98: "환불 상태:",
        text99: "검색",
        text100: "주문이 없습니다",
        text101: "주문 번호:",
        text102: "주문 정보:",
        text103: "사용자",
        text104: "거래 수량",
        text105: "단가",
        text106: "지불 금액",
        text107: "주문 조작:",
        text108: "거래 확인",
        text109: "결제 정보 보기",
        text110: "주문 번호 복사",
        text111: "결제 정보",
        text112: "미지불",
        text113: "미확인",
        text114: "고객 취소",
        text115: "거래 완료",
        text116: "거래자 취소",
        text117: "시간 초과 취소",
        text118: "시스템 취소",
        text119: "이상 심사 중",
        text120: "이상 심사 거부",
        text121: "이상 심사 승인",
        text122: "환불 정보 입력",
        text123: "환불 중",
        text124: "환불 완료",
        text125: "이미 입금됨",
        text126: "미입금",
        text127: "환불 실패",
        text128: "플랫폼 취소",
        text129: "조작 성공",
        text130: "복사 성공",
        text131: "복사 실패",
        text132: "빠른 판매 주문",
        text133: "판매 주문 일일 한도:",
        text134: "오늘 거래량:",
        text135: "수금 금액",
        text136: "지불",
        text137: "주문 취소",
        text138: "결제 확인",
        text139: "취소 성공",
        text140: "복사 성공",
        text141: "복사 실패",
        text142: "은행 카드",
        text143: "알리페이",
        text144: "카드 번호:",
        text145: "카드 소유자:",
        text146: "지점:",
        text147: "사용됨/일일 한도:",
        text148: "주문 수/상한:",
        text149: "주문 수락 여부:",
        text150: "계정",
        text151: "보유자:",
        text152: "거래 금액:",
        text153: "주문 수락 여부:",
        text154: "은행 카드 추가",
        text155: "알리페이 계정 추가",
        text156: "HIBIT 계정 추가",
        text157: "계정 삭제",
        text158: "상급에게 연락하여 알리페이 바인딩 권한을 활성화하세요",
        text159: "아직 실명 인증을 받지 않았습니다. 관련 정보를 제출하세요",
        text160: "실명: ",
        text161: "여권 번호:",
        text162: "실명을 입력하십시오",
        text163: "여권 번호를 입력하십시오",
        text164: "여권 사진",
        text165: "여권 소지 사진",
        text166: "신청 제출",
        text167: "시스템 심사 대기 중",
        text168: "실명 인증 성공",
        text169: "실명 인증 거부",
        text170: "재신청",
        text171: "사진을 업로드하십시오",
        text172: "실명을 입력하십시오",
        text173: "여권 번호를 입력하십시오",
        text174: "여권 사진을 업로드하십시오",
        text175: "여권을 소지한 셀카를 업로드하십시오",
        text176: "제출 성공, 심사 대기 중",
        text177: "입금 유형:",
        text178: "입금 주소:",
        text179: "입금 주소:",
        text180: "주소 복사",
        text181: "안내:",
        text182: "1. 최소 입금 금액은 USDT-BEP2020: 20; USDT-TRC20: 1; BTC: 0.003; ETH: 0.05입니다.",
        text183: "최소 금액 미만으로 입금할 경우 입금 및 환불이 불가능합니다.",
        text184: "2. BEP20의 예상 입금 시간은 2-10분이며, TRC20의 경우 0.1-5분이며, 블록체인 혼잡으로 인해 시간이 늘어날 수 있습니다.",
        text185: "3. BEP20 및 TRC20 주소는 서로 다르며 상호 호환되지 않습니다. 주소 유형을 주의 깊게 확인하십시오. 주소 유형이 일치하지 않으면 플랫폼이 복구를 지원할 수 없으므로 주소를 복사하여 붙여넣은 후 주소가 완전히 일치하는지 다시 한번 확인하십시오.",
        text186: "5. 주소를 복사하여 붙여넣은 후 주소의 앞, 중간 및 뒤가 완전히 일치하는지 주의하여 확인한 후 입금하십시오. 주소가 일치하지 않으면 플랫폼에서 복구를 지원할 수 없으므로 주소가 맞는지 다시 한 번 확인하십시오. 잃어버린 자산을 방지하십시오.",
        text187: "질문이 있으시면,",
        text188: "은행 이름:",
        text189: "은행 이름을 입력하십시오",
        text190: "계좌 번호:",
        text191: "계좌 번호를 입력하십시오",
        text192: "카드 소지자:",
        text193: "카드 소지자의 이름을 입력하십시오",
        text194: "개설 지점:",
        text195: "개설 지점을 입력하십시오",
        text196: "은행 주소:",
        text197: "은행 주소를 입력하십시오",
        text198: "은행 코드:",
        text199: "은행 코드를 입력하십시오",
        text200: "국제 송금 코드:",
        text201: "국제 송금 코드를 입력하십시오",
        text202: "매일 최대 수금 한도:",
        text203: "매일 최대 금액을 입력하십시오",
        text204: "주문 제한:",
        text205: "주문 제한을 입력하십시오",
        text206: "활성화 여부:",
        text207: "결제 비밀번호:",
        text208: "결제 비밀번호를 입력하십시오",
        text209: "Google 인증 코드:",
        text210: "Google 인증 코드를 입력하십시오",
        text211: "확인",
        text212: "은행 이름을 입력하십시오",
        text213: "계좌 번호를 입력하십시오",
        text214: "카드 소지자의 이름을 입력하십시오",
        text215: "개설 지점을 입력하십시오",
        text216: "은행 주소를 입력하십시오",
        text217: "은행 코드를 입력하십시오",
        text218: "국제 송금 코드를 입력하십시오",
        text219: "매일 최대 한도를 입력하십시오",
        text220: "주문 제한을 입력하십시오",
        text221: "결제 비밀번호를 입력하십시오",
        text222: "Google 인증 코드를 입력하십시오",
        text223: "계정:",
        text224: "HIBIT 계정을 입력하십시오",
        text225: "소지자 이름:",
        text226: "소지자 이름을 입력하십시오",
        text227: "수령용 2차원 코드:",
        text228: "활성화 여부:",
        text229: "결제 비밀번호를 입력하십시오",
        text230: "결제 비밀번호를 입력하십시오",
        text231: "Google 인증 코드를 입력하십시오",
        text232: "Google 인증 코드를 입력하십시오",
        text233: "수령용 2차원 코드를 업로드하십시오",
        text234: "추가 성공",
        text235: "계정:",
        text236: "알리페이 계정을 입력하십시오",
        text237: "지역 코드 검색",
        text238: "지역 코드를 선택하십시오!",
        text239: "수령 유형:",
        text240: "수령 계정:",
        text241: "수취인 이름:",
        text242: "은행 이름:",
        text243: '수취 코드:',
        text244: "지불 증명서를 업로드하십시오:",
        text245: "결제 확인",
        text246: "지불 증명서를 업로드하십시오",
        text247: "결제 성공",
        text248: "계정을 삭제하려고 합니다. 다음 검증을 완료하십시오",
        text249: "삭제 확인",
        text250: "삭제 성공",
        text251: "휴대 전송",
        text252: "수령 주소",
        text253: "금액",
        text254: "수수료",
        text255: "상태",
        text256: "시간",
        text257: "심사 대기 중",
        text258: "성공",
        text259: "실패",
        text260: "주문 번호:",
        text261: "주문 번호를 입력하세요:",
        text262: "거래 유형:",
        text263: "시간:",
        text264: "수량",
        text265: "총액",
        text266: "시간",
        text267: "매도",
        text268: "매도 취소",
        text269: "매수",
        text270: "입금",
        text271: "출금 차감",
        text272: "출금 취소",
        text273: "출금 수수료",
        text274: "환불 수수료",
        text275: "보증금",
        text276: "보증금 환불",
        text277: "획득",
        text278: "휴대폰 번호를 입력하세요",
        text279: "1. 계정 및 비밀번호",
        text280: "2. Google 바인딩",
        text281: "3. 등록 완료",
        text282: "휴대폰 번호:",
        text283: "로그인 비밀번호:",
        text284: "로그인 비밀번호를 입력하세요",
        text285: "지불 비밀번호: ",
        text286: "지불 비밀번호를 입력하세요",
        text287: "인증 코드:",
        text288: "SMS 인증 코드를 입력하세요",
        text289: "다음 단계",
        text290: "아래의 QR 코드를 스캔하여 Google 인증기를 바인딩하세요",
        text291: "Google 인증 코드:",
        text292: "인증 코드를 입력하세요",
        text293: "이전 단계",
        text294: "회원가입을 축하합니다",
        text295: "등록 완료",
        text296: "지불 비밀번호 형식은 6 자리 숫자입니다",
        text297: "Google 인증 코드 정보 가져오기 실패",
        text298: "Google 인증 코드를 입력하세요",
        text299: "등록 실패",
        text300: "지역 번호를 선택하세요",
        text301: "이 사용자는 이미 존재합니다",
        text302: "새로운 인증기 가져오기",
        text303: "바인딩",
        text304: "성공적으로 가져옴",
        text305: "새로운 인증 정보를 입력하세요",
        text306: "바인딩 성공",
        text307: "새로운 로그인 비밀번호:",
        text308: "새로운 로그인 비밀번호를 입력하세요",
        text309: "비밀번호 확인:",
        text310: "새로운 로그인 비밀번호를 확인하세요",
        text311: "변경 확인",
        text312: "두 번의 비밀번호 입력이 다릅니다",
        text313: "변경 성공",
        text314: "새로운 지불 비밀번호: (주의: 6 자리 숫자 여야 함)",
        text315: "새로운 지불 비밀번호를 입력하세요",
        text316: "지불 비밀번호 확인:",
        text317: "지불 비밀번호를 확인하세요",
        text318: "설정 확인",
        text319: "출처",
        text320: "금액",
        text321: "상태",
        text322: "시간",
        text323: "처리 중",
        text324: "성공",
        text325: "실패",
        text326: "언어 설정:",
        text327: "한도액 을 수정 하다",
        text328: "거래 한도를 설정하세요:",
        text329: "한도액을 입력하세요",
        text330: "확인",
        text331: "온라인 지갑 / 디지털 지갑",
        text332: "계정을 입력하세요",
        text333: "계정 유형:",
        text334: "Email",
        text335: "이메일 번호를 입력하십시오.",
        text336: "Email:",
        text337: "올바른 이메일 주소를 입력하십시오.",
        text338: ":",
        text339: ":",
        text340: ":",
        text341: ":",
        text342: "예외 처리",
        text343: "예외 유형:",
        text344: "비본인 실명 지불",
        text345: "결제 금액 불일치",
        text346: "지불을 받지 못했습니다.",
        text347: "환불 수량:",
        text348: "환불 수량을 입력하세요",
        text349: "예외 유형을 선택하십시오.",
        text350: "비어 있음",
        text351: "이름 형식 이 정확하지 않아 대문자 로 보전 하다",
        text352: "힌트",
        text353: "결제 계좌",
        text354: "지불 방식",
        text355: "결제 계정",
        text356: "지불인",
        text357: "수금 계좌",
        text358: "수금 방식",
        text359: "수금 계좌",
        text360: '수취인',
        text361: "거부 이유"
    }
}